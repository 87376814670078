<template lang="">
  <div class="flex justify-center items-center relative" v-click-outside="closeDropdown">
    <img :src="offlineIcon" class="relative z-10 block overflow-hidden  cursor-pointer select-none" @dblclick="isOpen = !isOpen" />

    <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default"></button>
    <div v-if="isOpen" class="absolute z-50 py-2 mt-2 rounded-lg bg-sm-dark-indigo shadow-xl min-w-max px-5" :class="positionStyle">
      <div>
        <div class="w-full grid grid-cols-4 gap-5 my-5 px-5">
          <div class="text-sm text-white flex items-center pl-2">단말 선택</div>
          <div class="col-span-3">
            <t-rich-select
              :options="deviceList"
              class="rounded-md py-1 mx-2 text-gray-700  outline-none w-full"
              textAttribute="deviceID"
              valueAttribute="deviceGuid"
              :hideSearchBox="false"
              v-model="deviceGuid"
            >
            </t-rich-select>
          </div>

          <div class="text-sm text-white flex items-center pl-2">탐지 알림</div>
          <div class="col-span-3">
            <t-rich-select
              :options="soundList"
              class="rounded-md py-1 mx-2 text-gray-700  outline-none w-full"
              textAttribute="soundName"
              valueAttribute="soundGuid"
              :hideSearchBox="false"
              v-model="soundDetectGuid"
            >
            </t-rich-select>
          </div>

          <div class="text-sm text-white flex items-center pl-2">오프라인 알림</div>
          <div class="col-span-3">
            <t-rich-select
              :options="soundList"
              class="rounded-md py-1 mx-2 text-gray-700  outline-none w-full"
              textAttribute="soundName"
              valueAttribute="soundGuid"
              :hideSearchBox="false"
              v-model="soundOfflineGuid"
            >
            </t-rich-select>
          </div>

          <div class="text-sm text-white flex items-center pl-2">메모</div>
          <div class="col-span-3"><input type="text" class="rounded-md py-2 px-3 mx-2 text-gray-700  outline-none w-full" v-model="deviceMemo" /></div>
        </div>

        <!-- <div v-if="getUserRole === 'master' || getUserRole === 'admin'"> -->
        <div>
          <div class="w-full mb-5 flex justify-between items-center" v-if="oldDeviceGuid===''">
            <button class="text-white font-bold text-sm bg-sm-blue-grey w-full py-1 rounded-xl mx-3" @click="isOpen = false">취소</button>
            <button class="text-white font-bold text-sm bg-sm-coral-pink w-full py-1 rounded-xl mx-3" @click="removeAlertIcon()">삭제</button>
            <button class="text-white font-bold text-sm bg-blue-500 w-full py-1 rounded-xl mx-3" @click="registMapDevice()">확인</button>
          </div>

          <div class="w-full mb-5 flex justify-between items-center" v-else>
            <button class="text-white font-bold text-sm bg-sm-blue-grey w-full py-1 rounded-xl mx-3" @click="isOpen = false">취소</button>
            <button class="text-white font-bold text-sm bg-sm-coral-pink w-full py-1 rounded-xl mx-3" @click="deleteMapDevice()">삭제</button>
            <button class="text-white font-bold text-sm bg-sm-aquamarine w-full py-1 rounded-xl mx-3" @click="updateMapDevice()">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'

import { requestApi } from "@/utils/api"

export default {
  props: {
    companyGuid: { type: String },
    siteGuid: { type: String },
    mapGuid: { type: String },
    oldDeviceGuid: { type: String },
    pointX: { type: String },
    pointY: { type: String },
    mapImgWidth: { type: Number },
    mapImgHeight: { type: Number },
    index: { type: Number }
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      isOpen: false,
      offlineIcon: require("@/assets/images/icon/ic-list-offline-signal.png"),

      position: '',
      positionStyle: "top-1 left-1",
      
      deviceList: [],
      soundList: [],
      
      deviceGuid: '',
      soundDetectGuid: '',
      soundOfflineGuid: '',
      deviceMemo: '',
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser','getUserRole']),
  },
  created () {
  },
  methods: {
    removeAlertIcon () {
      this.isOpen = false
      this.$emit("removeAlertIcon", this.index)
    },
    closeDropdown () {
      this.isOpen = false
    },
    async getDeviceInfo () {
      let reqObj = {}
      reqObj.target = `/device/info/${this.oldDeviceGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.deviceList.push(result.msg.retData)

        this.deviceGuid = result.msg.retData.deviceGuid
        this.soundDetectGuid = result.msg.retData.soundAlertGuid
        this.soundOfflineGuid = result.msg.retData.soundDisconnectGuid
        this.deviceMemo = result.msg.retData.deviceMemo
      }
    },

    async getDeviceListNotAssign () {
      let reqObj = {}
      reqObj.target = `/device/listready/${this.companyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.deviceList = this.deviceList.concat(result.msg.retData);
      }
    },
    async getSoundList () {
      let reqObj = {}
      reqObj.target = `/setting/sound/listall/${this.companyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.soundList = result.msg.retData;
      }
    },
    registMapDevice () {
      this.registMapDeviceAction()
      setTimeout(() => {
        this.$emit("refreshAlertIcon")
      }, 1000)
    },
    async registMapDeviceAction () {
      let reqObj = {}
      reqObj.target = `/device/assign`;
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        "deviceGuid": this.deviceGuid,
        "mapGuid": this.mapGuid,
        "pointX": this.pointX,
        "pointY": this.pointY,
        "soundAlertGuid": this.soundDetectGuid,
        "soundDisconnectGuid": this.soundOfflineGuid,
        "deviceMemo": this.deviceMemo
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.isOpen = false
      }
    },
    deleteMapDevice () {
      this.$swal({
        title: "삭제",
        text: `선택한 단말기를 삭제하시겠습니까?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        cancelButtonText: "취소"
      }).then((result) => { // <--
        if (result.value) { 
          this.deleteMapDeviceAction('close')
        }
      });
    },
    async deleteMapDeviceAction (type) {
      let reqObj = {}
      reqObj.target = `/device/retrieve`;
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        "deviceGuid": this.oldDeviceGuid,
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(type === 'close') {
          this.isOpen = false
          this.$emit("removeAlertIcon", this.index)
        }
      }
    },

    async assignUpdateDeviceAction () {
      let reqObj = {}
      reqObj.target = `/device/assignupdate`;
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        "deviceGuid": this.deviceGuid,
        "pointX": this.pointX,
        "pointY": this.pointY,
        "soundAlertGuid": this.soundDetectGuid,
        "soundDisconnectGuid": this.soundOfflineGuid,
        "deviceMemo": this.deviceMemo
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.isOpen = false
      }
    },

    updateMapDevice () {
      if(this.deviceGuid != this.oldDeviceGuid) {
        // console.log("디바이스 변경 했네.")
        this.deleteMapDeviceAction('update')
        this.registMapDeviceAction();
        setTimeout(() => {
          this.$emit("refreshAlertIcon")
        }, 1000)
      }
      else {
        // console.log("사운드만 바꾸는 건가...")
        this.assignUpdateDeviceAction();
      }
      this.isOpen = false
    }
  },
  watch: {
    'isOpen': function () {
      if(this.isOpen) {
        // console.log("check regist", this.deviceGuid, this.mapImgWidth, this.mapImgHeight, this.pointX.slice(0,-2), this.pointY.slice(0,-2))
        let positionStr = ""
        if((this.mapImgHeight / 2) > this.pointY.slice(0,-2) ) positionStr += "top-1"
        else positionStr += "bottom-1"

        if((this.mapImgWidth / 2) > this.pointX.slice(0,-2) ) positionStr += " left-12"
        else positionStr += " right-12"

        this.positionStyle = positionStr

        this.deviceList = [];
        this.getDeviceListNotAssign()
        this.getSoundList()

        if(this.oldDeviceGuid!=='') {
          this.getDeviceInfo()
        }
      }
    }
  }
}
</script>
<style lang="">
</style>